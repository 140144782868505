<template>
  <div>
    <div class="mb-4 d-flex justify-content-between">
      <div class="">
        <h4 class="">Informasi Umum</h4>
      </div>
      <div class="">
        <a-button v-if="!isEdit" @click.prevent="clickEdit" type="default">
          <edit-outlined></edit-outlined>
          Edit</a-button>
        <template v-else>
          <a-button class="text-success" @click.prevent="saveEdit" type="link">Simpan</a-button>
          <a-divider type="vertical" class="mx-1"></a-divider>
          <a-button class="text-danger" @click.prevent="() => isEdit = false" type="link">Batal</a-button>
        </template>
      </div>
    </div>
    <div>
        <!-- <a-form-item required label="Nama Course">
          <a-input v-model:value="courseGeneralForm.name" />
        </a-form-item> -->
      <a-form :model="courseGeneralForm" layout="vertical" hide-required-mark>
        <a-row :gutter="[32,0]">
          <a-col :md="24" v-if="isEdit" :lg="12">
            <a-form-item v-bind="validateInfos.name">
              <template #label>
                <label for="Nama Course" class="font-weight-bold mb-0">Judul Course</label>
              </template>
              <a-textarea v-model:value="courseGeneralForm.name" :max-length="90" show-count />
            </a-form-item>
          </a-col>
          <a-col v-if="isEdit" :md="24" :lg="12">
            <a-form-item>
              <template #label>
                <label for="statusPublication" class="font-weight-bold mb-0">Status</label>
              </template>
              <!-- <a-select v-model:value="courseGeneralForm.statusPublication">
                <a-select-option value="draft">Draf</a-select-option>
                <a-select-option value="published">Terpublikasi</a-select-option>
              </a-select> -->
              <a-radio-group v-model:value="courseGeneralForm.statusPublication" button-style="solid">
                <a-radio-button value="draft">Draf</a-radio-button>
                <a-radio-button class="success-btn" value="published">Terpublikasi</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item>
              <template #label>
                <label for="Deskripsi" class="font-weight-bold mb-0">Deskripsi</label>
              </template>
              <a-textarea v-if="isEdit" :rows="6" v-model:value="courseGeneralForm.description"></a-textarea>
              <p class="my-0" v-else>{{courseData.description || '-'}}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item name="vendorId" v-bind="validateInfos.vendorId">
              <template #label>
                <label for="Vendor/Instruktur" class="font-weight-bold mb-0">Vendor/Instruktur</label>
              </template>
              <a-select v-if="isEdit" v-model:value="courseGeneralForm.vendorId">
                <a-select-option v-for="vendor in vendors" :key="vendor._id" :value="vendor._id">{{vendor.name}}</a-select-option>
              </a-select>
              <p class="my-0" v-else>{{vendorName}}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item name="categoryId" v-bind="validateInfos.categoryId">
              <template #label>
                <label for="Kategori" class="font-weight-bold mb-0">Kategori</label>
              </template>
              <a-select v-if="isEdit" v-model:value="courseGeneralForm.categoryId">
                <a-select-option v-for="category in categories" :key="category._id" :value="category._id">{{category.name}}</a-select-option>
              </a-select>
              <p class="my-0" v-else>{{categoryName}}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item>
              <template #label>
                <label for="Tipe Course" class="font-weight-bold mb-0">Tipe Course</label>
              </template>
              <a-select v-if="isEdit" v-model:value="courseGeneralForm.type">
                <a-select-option value="free">Gratis</a-select-option>
                <a-select-option value="premium">Berbayar</a-select-option>
              </a-select>
              <p class="my-0" v-else>{{courseData.type === 'premium' ? 'Berbayar' : 'Gratis'}}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12" v-if="(!isEdit && courseData.type === 'premium') || (isEdit && courseGeneralForm.type === 'premium')">
            <a-form-item v-bind="validateInfos.price">
              <template #label>
                <label for="Harga" class="font-weight-bold mb-0">Harga</label>
              </template>
              <div class="d-flex align-items-center" v-if="isEdit">
                <div>Rp</div>
                <a-input-number
                  class="w-100 ml-2 mr-1"
                  :min="1"
                  v-model:value="courseGeneralForm.price"
                />
              </div>
              <p class="my-0" v-else>{{rupiahPrice(courseData.price)}}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item>
              <template #label>
                <label for="Kelas Pilihan" class="font-weight-bold mb-0">Course Pilihan?</label>
              </template>
              <div class="d-flex align-items-center" v-if="isEdit">
                <a-radio-group v-model:value="courseGeneralForm.isFeatured">
                  <a-radio value="yes">
                    Ya
                  </a-radio>
                  <a-radio value="no">
                    Tidak
                  </a-radio>
                </a-radio-group>
              </div>
              <p class="my-0" v-else>{{courseData.isFeatured ? 'Ya' : 'Tidak'}}</p>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item>
              <template #label>
                <label for="Kelas Banner" class="font-weight-bold mb-0">Course Banner?</label>
              </template>
              <div class="d-flex align-items-center" v-if="isEdit">
                <a-radio-group v-model:value="courseGeneralForm.isBanner">
                  <a-radio value="yes">
                    Ya
                  </a-radio>
                  <a-radio value="no">
                    Tidak
                  </a-radio>
                </a-radio-group>
              </div>
              <p class="my-0" v-else>{{courseData.isBanner ? 'Ya' : 'Tidak'}}</p>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- <div v-else>
      <a-row>
        <a-col :span="24">
         <div class="font-weight-bold">Deskripsi</div>
         <p>{{courseData.description}}</p>
        </a-col>
      </a-row>
    </div> -->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs, watch, createVNode, computed } from 'vue'
import { useStore } from 'vuex'
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons-vue'
import rupiahPrice from '@/helpers/rupiahPrice'
import { Modal, notification } from 'ant-design-vue';
import { useForm } from '@ant-design-vue/use';
import { useRoute } from 'vue-router'

export default {
  components: { EditOutlined },
  props: {
    course: {
      type: Object,
      default: () => { return {} },
    },
    vendors: {
      type: Array,
      default: () => { return [] },
    },
    categories: {
      type: Array,
      default: () => { return [] },
    },
  },
  emits: ['fetchCourse'],
  setup(props, { emit }) {
    const { course:courseData } = toRefs(props)
    const store = useStore()
    const route = useRoute()
    const idCourse = route.params.id
    const { vendors, categories } = toRefs(props)
    let courseGeneralForm = ref({
      name: null,
      description: null,
      price: 0,
      type: 'free',
      vendorId: null,
      categoryId: null,
      isFeatured: "no",
      isBanner: 'no',
      statusPublication: null,
    })

    let categoryName = computed(() => {
      if ('category' in courseData.value) {
        if (categories.value.length) return categories.value.find(cat => cat._id === courseData.value.category._id ).name
        return '-'
      }

      return '-'
    })
    let vendorName = computed(() => {
      if ('vendor' in courseData.value) {
        if (vendors.value.length) return vendors.value.find(vendor => vendor._id === courseData.value.vendor._id ).name
        return '-'
      }
      return '-'
    })

    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: 'Nama harus diisi!',
          trigger: 'change',
        },
      ],
      categoryId: [
        {
          required: true,
          message: 'Kategori harus diisi!',
          trigger: 'change',
        },
      ],
      vendorId: [
        {
          required: true,
          message: 'Vendor harus diisi!',
          trigger: 'change',
        },
      ],
      // price: [
      //   {
      //     required: true,
      //     type: 'number',
      //     message: 'Apabila berbayar, harga harus diisi!',
      //     trigger: 'change',
      //   },
      // ],
    })

    const clickEdit = async () => {
      isEdit.value = true
      const { value: course } = courseData
      courseGeneralForm.value = {
        name: course.name,
        description: course.description,
        price: course.price,
        type: course.type,
        vendorId: course.vendorId,
        categoryId: course.category._id,
        isFeatured: course.isFeatured ? "yes" : "no",
        isBanner: course.isBanner ? "yes" : "no",
        statusPublication: course.statusPublication,
      }
    }

    const { resetFields, validate, validateInfos } = useForm(courseGeneralForm, rulesRef);

    const saveEdit = async () => {
      try {
        const { value: form } = courseGeneralForm
        const payload = {
          id: idCourse,
          data: {
            ...form,
            isFeatured: form.isFeatured === 'yes' ? true : false,
            isBanner: form.isBanner === 'yes' ? true : false,
          },
        }
        await validate()
        Modal.confirm({
          title: `Apa anda yakin ingin mengubah data pada course?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const data = await store.dispatch('course/UPDATE_COURSE', payload)
              isEdit.value = false

              notification.success({
                message: `Berhasil Mengubah Data Pada Course`,
              })
              emit('fetchCourse')
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch (err) {
        console.log(err)
      }
    }

    let isEdit = ref(false)

    onMounted(async () => {
    })


    // watch(courseData, newVal => {

    // })

    return {
      courseGeneralForm,
      isEdit,
      courseData,
      clickEdit,
      saveEdit,
      rupiahPrice,
      validateInfos,
      rulesRef,
      categoryName,
      vendorName,
    }
  },
}
</script>

<style>

</style>
